<template>
  <CCard no-header>
    <CCardBody>
      <h3>{{ operationName }} Inventory</h3>
      <CAlert :color="alertType" v-if="message">
        {{ message }}
      </CAlert>

      <div v-if="isLoadingRequired == false">
        <CRow>
          <CCol>
            <CInput label="Name" type="text" v-model="inventory.name"></CInput>
          </CCol>
        </CRow>
        <CRow v-if="iCalendarLink == null">
          <CCol>
            <label>Click the below button to create your icalendar link</label>
            <CRow>
              <CButton style="margin-left:15px" color="primary" @click="generateInventoryGroupIcal()">
                <CIcon name="cil-plus" /> Generate your link
              </CButton>
            </CRow>

          </CCol>
        </CRow>
        <CRow v-if="iCalendarLink != null">
          <CCol>
            <CInput disabled label="Calendar Link" type="text" v-model="iCalendarLink">
            </CInput>
          </CCol>
          <CCol style="margin-top:30px">
            <CButton @click="copyText()" color="link" title="Copy to clipboard">
              <CIcon name="cil-save" />
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6"></CCol>
          <CCol class="d-flex justify-content-end">
            <CButton color="secondary" class="btn-lg" @click="goBack">
              <CIcon name="cil-list" /> Back to the list
            </CButton>
            <CButton color="primary" @click="save()" class="btn-lg" style="margin-left: 15px">
              <CIcon name="cil-save" /> Save
            </CButton>
          </CCol>
        </CRow>

        <br />
        <CRow>
          <CCol>
            <CButton color="success" v-if="inventory.id != emptyGuid" @click="addNewInventoryItem()" style="margin-right: 15px" class="btn">
              <CIcon name="cil-cart" />
              Add inventory item
            </CButton>
          </CCol>
        </CRow>
        <br />
        <CModal v-model="inventoryItem.id" :title="inventoryItemModalTitle" :color="inventoryItemModalType"
          :show.sync="inventoryItemModal" @update:show="closeInventoryItemModal">
          <div v-if="isDelete == false">
            <!-- <CRow style="margin-bottom: 1rem" v-if="isEdit == false">
              <CCol sm="3">
                <CInputRadio @change="selectFromExisting = false" label="Add New" type="radio" name="types"
                  :checked.sync="addNew" />
              </CCol>
              <CCol sm="5">
                <CInputRadio @change="addNew = false" label="Select From Existing" type="radio" name="types"
                  :checked.sync="selectFromExisting" />
              </CCol>
            </CRow> -->
            <div v-if="addNew == true">
              <CRow>
                <CCol col="12">
                  <CInput label="Name" v-model="inventoryItem.name" />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <CInput label="Info Email" v-model="inventoryItem.responsibleEmailsSeparatedByComma" />
                </CCol>
              </CRow>

              <CRow v-if="this.inventoryItemModalAction == 'add'">
                <CCol col="12">
                  <label>Member of Inventory Groups</label>
                  <CMultiSelect
                  v-if="inventoryItems && inventoryItemsLoaded"
              :multiple="true"
              :selection="true"
              v-model="inventoryItem.inventoryIds"
              @update="appendValue($event)"
              optionsEmptyPlaceholder="No options"
              selectionType="text"
              :options="parents"
              :selected="inventoryItem.inventoryIds"
            />
                  <!-- <CSelect label="Change parent of the item" :value.sync="inventoryItem.inventoryId" :plain="true"
                    :options="parents" placeholder="Select a parent">
                  </CSelect> -->
                </CCol>
              </CRow>
              <CRow style="margin-left:15px" v-if="this.inventoryItemModalType == 'info'">
                <CRow v-if="iItemCalendarLink == null">
                  <CCol>
                    <label>Click the below button to create your icalendar link</label>
                    <CRow>
                      <CButton style="margin-left:15px" color="primary" @click="generateInventoryItemIcal()">
                        <CIcon name="cil-plus" /> Generate your link
                      </CButton>
                    </CRow>

                  </CCol>
                </CRow>
                <CRow v-if="iItemCalendarLink != null">
                  <CCol col="12">
                    <CInput disabled label="Calendar Link" type="text" v-model="iItemCalendarLink">
                    </CInput>
                  </CCol>
                  <CCol style="margin-top:30px">
                    <CButton @click="copyTextItem()" color="link" title="Copy to clipboard">
                      <CIcon name="cil-save" />
                    </CButton>
                  </CCol>
                </CRow>
              </CRow>
            </div>
            <div v-if="selectFromExisting">
              <CCol col="12">
                <CSelect label="Select an inventory from a different group" :value.sync="inventoryItem.id" :plain="true"
                  :options="allInventoryItems" placeholder="Select an inventory">
                </CSelect>
              </CCol>
            </div>
          </div>

        </CModal>

        <CDataTable :items="inventoryItems" :fields="inventoryItemFields" hover
          v-if="inventory.id != emptyGuid && inventoryItems.length > 0">
          <template #name="{ item }">
            <td @click="goToItem(item.id)">
              <strong>{{ item.name }}</strong>
            </td>
          </template>
          <template #EDIT="{ item }">
            <td style="width: 15%">
              <CButton color="primary" @click="editInventoryItem(item)">
                <CIcon name="cil-pencil" /> Edit
              </CButton>
            </td>
          </template>
          <template #DELETE="{ item }">
            <td style="width: 15%">
              <CButton color="danger" @click="
                inventoryItemModalTitle = 'Are You sure to delete inventory item ?';
              inventoryItemModalAction = 'delete';
              schemaId = item.id;
              inventoryItemModal = true;
              inventoryItemModalType = 'danger';
              inventoryItem = item;
              isDelete = true;
              ">
                <CIcon name="cil-trash" /> Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
      </div>

      <CSpinner style="display: block; position: fixed; top: 50%; left: 50%" v-if="isLoadingRequired === true"
        color="primary" />
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import { nextTick } from 'vue';

export default {
  name: "AddNewInventoryItem",
  data: () => {
    return {
      operationName: "Create New",
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      addNew: true,
      selectFromExisting: false,
      isDelete: false,
      isEdit: false,
      iCalendarLink: null,

      iItemCalendarLink: null,
      // Alert
      alertType: "success",
      message: "",

      //parents of the items
      parents: [],

      allInventoryItems: [],

      isLoadingRequired: false,

      // Load
      inventory: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        calendarLink: null,
      },

      //inventoryItem
      inventoryItems: [],
      inventoryItem: {
        id: "00000000-0000-0000-0000-000000000000",
        inventoryId: "00000000-0000-0000-0000-000000000000",
        name: null,
        responsibleEmailsSeparatedByComma: null
      },

      inventoryItemFields: ["name", "EDIT", "DELETE"],
      inventoryItemModalTitle: null,
      inventoryItemModal: false,
      inventoryItemModalAction: null,
      inventoryItemModalType: "danger",
      inventoryItemsLoaded: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToItem(itemId) {
      this.$router.push({ name: "InventoryCalendar", query: { inventoryId: this.inventory.id, inventoryItemId: itemId } });
    },
    getAllInventories() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ListItem/GetAllInventoryItems/ExceptGroup/${this.$route.params.id}`)
        .then(function (response) {
          self.allInventoryItems = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    get(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/Inventory/${id}`)
        .then(function (response) {
          self.inventory = response.data;
          self.iCalendarLink = self.inventory.calendarLink;
          self.getInentoryItemList(self.inventory.id);
          self.getParents();
          self.getAllInventories();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    save() {
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/Inventory`, self.inventory)
        .then((response) => {
          self.inventory.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully created inventory.";
          self.getParents();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    copyText() {
      navigator.clipboard.writeText(this.iCalendarLink);
                this.alertType = "success";
          setTimeout(function () {
              this.message = null;
          }, 5000);
      this.message = "Successfully copied.";
    },
    copyTextItem() {
      navigator.clipboard.writeText(this.iItemCalendarLink);
                this.alertType = "success";
          setTimeout(function () {
              this.message = null;
          }, 5000);
      this.message = "Successfully copied.";
    },
    generateInventoryGroupIcal() {
      this.isLoadingRequired = true;
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ICalendar/group/${this.inventory.id}`)
        .then(function (response) {
          self.iCalendarLink = response.data;
          self.isLoadingRequired = false;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
          self.isLoadingRequired = false;
        });
    },
    editInventoryItem(item){
      this.inventoryItemsLoaded = false;
      this.inventoryItemModalTitle = 'Edit inventory item';
      this.inventoryItemModal = true;
      this.inventoryItemModalAction = 'add';
      this.inventoryItemModalType = 'info';
      this.inventoryItem = item;
      this.isEdit = true;
      nextTick(() => {
        this.inventoryItemsLoaded = true;
      });
    },
    addNewInventoryItem(){
      this.inventoryItemsLoaded = false;
      this.inventoryItem = {
        id: '00000000-0000-0000-0000-000000000000',
        inventoryIds: [this.inventory.id],
        name: null,
        responsibleEmailsSeparatedByComma: null
      };
      this.inventoryItemModal = true;
      this.inventoryItemModalType = 'success';
      this.inventoryItemModalAction = 'add';
      this.inventoryItemModalTitle = 'Add new inventory item';
      nextTick(() => {
        this.inventoryItemsLoaded = true;
      });
    },
    generateInventoryItemIcal() {
      this.isLoadingRequired = true;
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ICalendar/item/${this.inventoryItem.id}`)
        .then(function (response) {
          self.iItemCalendarLink = response.data;
          self.isLoadingRequired = false;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
          self.isLoadingRequired = false;

        });
    },
    appendValue(event) {
      this.inventoryItem.inventoryIds = event;
    },
    addInventoryItem() {
      let self = this;
      if (this.selectFromExisting == false) {
        if (!self.inventoryItem.name) {
          self.failed = true;
          self.message = "Please enter all needed fields.";
          self.loading = false;
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          return;
        }
      }
      self.inventoryItem.inventoryIds = self.inventoryItem.inventoryIds.join(',');
      if (this.selectFromExisting == false) {
        axios
          .post(`${this.$apiAdress}/v1/InventoryItem/`, self.inventoryItem)
          .then(function (response) {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully updated inventory item.";
            self.get(self.inventory.id);
            self.cleanInventoryItem();
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error.response.data.message;
          });
      } else {
        self.inventoryItem.inventoryId = self.inventory.id;
        axios
          .post(`${this.$apiAdress}/v1/InventoryItem/createExisting`, self.inventoryItem)
          .then(function (response) {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully updated inventory item.";
            self.get(self.inventory.id);
            self.cleanInventoryItem();
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error.response.data.message;
          });
      }

    },

    cleanInventoryItem() {
      let self = this;
      self.inventoryItem.id = "00000000-0000-0000-0000-000000000000";
      self.inventoryItem.name = null;
      self.iItemCalendarLink = null;
    },

    closeInventoryItemModal(status, evt, accept) {
      if (accept) {
        if (this.inventoryItemModalAction == "add") {
          this.addInventoryItem();
        } else if (this.inventoryItemModalAction == "delete") {
          this.deleteInventoryItem(this.inventoryItem.id);
        } else {
          this.addInventoryItem();
        }
        self.cleanInventoryItem();
      }
      self.iItemCalendarLink = null;
      this.isDelete = false;
      this.isEdit = false;
    },

    deleteInventoryItem(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/InventoryItem/${id}`)
        .then(function (response) {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted inventory item.";
          self.cleanInventoryItem();
          self.get(self.inventory.id);
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getInentoryItemList(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/InventoryItem/List/${id}`)
        .then(function (response) {
          self.inventoryItems = response.data.map(value => {
            return {...value,
            inventoryIds: value.inventoryIds.split(',')
          };
          });
          self.inventoryItemsLoaded = true;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getParents() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ListItem/InventoryList`)
        .then(function (response) {
          self.parents = response.data.map((parent) => {
          return {
            value: parent.value,
            text: parent.label
          };
        });
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    }
  },
  mounted: function () {
    if (this.$route.params.id != this.emptyGuid) {
      this.operationName = "Edit";
      this.get(this.$route.params.id);
    }
  },
};
</script>
